/**
 * This is just a set of overrides to help with migration from
 * the old Middleman design and Contentful blog posts
 * where content writers use H1s and messy DOM elements for things.
 * Ideally we'd migrate to RichText in Contentful and have these fully controlled.
 *  For now it's just some CSS hacks instead
 */

import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const ContentStyles = styled.div`
  h1 {
    margin-top: ${getDimension('size10')};
  }

  h2 {
    margin-top: ${getDimension('size10')};
  }

  h3 {
    margin-top: ${getDimension('size10')};
  }

  h4 {
    margin-top: ${getDimension('size10')};
  }

  h5 {
    margin-top: ${getDimension('size10')};
  }

  h6 {
    margin-top: ${getDimension('size10')};
    padding: ${getDimension('size6')};
    background-color: ${props => props.theme.colors.cream};
    line-height: 1.6;
  }

  li {
    padding: ${getDimension('size1')} 0;
    line-height: ${props => props.theme.baseText.lineHeight};
  }

  table {
    border-collapse: collapse;
    margin-bottom: ${getDimension('size10')};
  }

  th {
    text-align: left;
    padding: ${getDimension('size4')};
    font-weight: 700;
    border-bottom: 1px solid ${props => props.theme.colors.black};
  }

  td {
    padding: ${getDimension('size4')};
    border-bottom: 1px solid ${props => props.theme.colors.black};
  }
`;
